<template>
	<div class="flex" style="flex-direction: column; height: 100%">
		<div class="tableModule">
			<div class="title">
				<div class="title-left">
					<i class="el-icon-s-operation"></i>
					<span>数据列表</span>
				</div>
				<div class="title-right">
					<el-upload ref="upload" style="display: inline-block;margin:0 10px;" action=""
						:on-change="handleChange" :show-file-list="false" :file-list="fileList" :limit="1"
						accept=".xls,.xlsx" :auto-upload="false">
						<el-button type="primary" size="medium" style="margin-right: 8px">导入表格</el-button>
					</el-upload>
					<el-button type="primary" size="medium" @click="insert()" style="margin-right: 8px">新增绑定</el-button>
				</div>
			</div>
			<div class="tableContainer">
				<div class="table" ref="tableContainer">
					<div>
						<el-table ref="usertable" :data="tableData.records" border :height="tableHeight"
							:header-cell-style="{ 'background-color': '#f9fafc' }" :cell-style="{ padding: 0 }"
							style="margin-bottom: 20px" v-loading="loading">

							<el-table-column prop="ruibangMeasId" align="center" label="瑞邦监测点id"
								show-overflow-tooltip></el-table-column>
							<el-table-column prop="ruibangMeasName" align="center" label="瑞邦监测点名称"
								show-overflow-tooltip></el-table-column>
							<el-table-column prop="ruibangMeasType" align="center" label="瑞邦监测点类型"
								show-overflow-tooltip></el-table-column>
							<el-table-column prop="ruibangUnitId" align="center" label="瑞邦设备id"
								show-overflow-tooltip></el-table-column>
							<el-table-column prop="ruibangUnitName" align="center" label="瑞邦设备名称"
								show-overflow-tooltip></el-table-column>
							<el-table-column prop="ruibangUnitType" align="center" label="瑞邦设备类型"
								show-overflow-tooltip></el-table-column>
							<el-table-column prop="ruibangArea" align="center" label="区域"
								show-overflow-tooltip></el-table-column>
							<el-table-column prop="ruibangDangwei" align="center" label="瑞邦单位"
								show-overflow-tooltip></el-table-column>
							<el-table-column prop="entName" align="center" label="企业名称"
								show-overflow-tooltip></el-table-column>
							<el-table-column prop="measId" align="center" label="测点id"
								show-overflow-tooltip></el-table-column>
							<el-table-column prop="measName" align="center" label="测点名称"
								show-overflow-tooltip></el-table-column>
							<el-table-column fixed="right" align="center" label="操作" width="230">
								<template slot-scope="scope">
									<el-button type="text" @click="editName(scope.row)">编辑</el-button>
									<el-button type="text" @click="remove(scope.row)">删除绑定</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<div class="pagination">
					<el-pagination style="float: right" @current-change="handleCurrentChange"
						:current-page.sync="searchParm.page" :page-size="searchParm.pageSize"
						layout="total, prev, pager, next,jumper" :total="tableData.total"></el-pagination>
				</div>
			</div>
		</div>
		<insert :isShow.sync="showInsertDialog" :dialogObj="insertObj"></insert>
	</div>
</template>
<script>
	import smCard from '@c/smCard.vue';
	import insert from './insert.vue';
	export default {
		components: {
			smCard,
			insert
		},
		data() {
			return {
				tableHeight: 0,
				tableData: {
					records: []
				},
				searchParm: {
					pageSize: 20,
					page: 1
				},
				showInsertDialog: false,
				insertObj: {},
				showEditDialog: false,
				editObj: {},
				showCopyInsertDialog: false,
				copyInsertObj: {},
				loading: false,
				newFile: new FormData(),
				fileList: [],
			};
		},
		methods: {
			//自适应表格高度
			calHeight() {
				this.$nextTick(() => {
					const rect = this.$refs.tableContainer.getBoundingClientRect();
					this.tableHeight = rect.height;
				});
			},
			//上传前校验
			handleChange(file, fileList) {
				if (this.fileList.length > 0) {
					this.$message.error('只能上传一份');
					return false;
				}
				const fileType = file.name.substring(file.name.lastIndexOf('.'));
				if (fileType != '.xls' && fileType != '.xlsx') {
					this.$message.error('上传文件只能是 excel 文件格式!');
				} else if (file) {
					if (this.newFile.has("file")) {
						this.newFile.delete("file");
					}
					this.newFile.append('file', file.raw);
					this.uploadExcel()
				}
			},
			uploadExcel() {
				this.loading = true;
				this.$postData("/data-api/v1/eqp-set-unit-meas-ruibang/excelExportData", this.newFile).then((res) => {
					if (res.code == 1000) {
						this.loading = false;
						this.newFile = new FormData();
						this.fileList = [];
						this.$message.success(res.msg);
						this.list();
					} else {
						this.loading = false;
						this.newFile = new FormData();
						this.fileList = [];
						this.$message.error(res.msg);
					}
				});
			},
			//清空搜索
			clearSearch() {
				this.searchParm = {
					pageSize: 20,
					page: 1
				};
				this.list();
			},
			//列表数据
			list() {
				this.$get('/data-api/v1/eqp-set-unit-meas-ruibang', this.searchParm).then(res => {
					if (res.code == 1000) {
						this.tableData = res.data;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			//打开新增弹窗
			insert() {
				this.insertObj['type'] = 1;
				this.showInsertDialog = true;
			},
			//打开新增弹窗
			editName(row) {
				this.insertObj['row'] = row;
				this.insertObj['type'] = 2;
				this.showInsertDialog = true;
			},
			//打开编辑弹窗
			edit(row) {
				this.editObj = row;
				this.showEditDialog = true;
			},
			//分页选择页面回调
			handleCurrentChange(val) {
				this.searchParm.page = val;
				this.list();
			},
			//删除
			remove(row) {
				this.$confirm('是否确认删除？', '删除绑定', {
						dangerouslyUseHTMLString: true,
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					})
					.then(() => {
						this.$remove('/data-api/v1/eqp-set-unit-meas-ruibang/' + row.id).then(res => {
							if (res.code == 1000) {
								this.$message.success(res.msg);
								//刷新表格
								this.list();
							} else {
								this.$message.error(res.msg);
							}
						});
					})
					.catch(() => {});
			}
		},
		mounted() {
			this.list();
			this.calHeight();
			window.addEventListener('resize', this.calHeight);
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.calHeight);
		}
	};
</script>
<style lang="scss" scoped>
	@import '@style/userCenter.scss';
</style>