<template>
	<el-dialog class="dialog" title="新增" :visible.sync="visiable">
		<div v-loading="loading" class="dlalog-body">
			<el-form ref="form" :rules="rules" :model="formData" label-width="180px">
				<el-row>
					<el-col :span="12">
						<el-form-item label="瑞邦测点id" prop="ruibangMeasId">
							<el-input v-model="formData.ruibangMeasId" :disabled="dialogObj.type == 2"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="瑞邦测点名称" prop="ruibangMeasName">
							<el-input v-model="formData.ruibangMeasName"></el-input>
						</el-form-item>
					</el-col>
					
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="区域" prop="ruibangArea">
							<el-input v-model="formData.ruibangArea"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="瑞邦设备id" prop="ruibangUnitId">
							<el-input v-model="formData.ruibangUnitId"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					
					<el-col :span="12">
						<el-form-item label="瑞邦设备名称" prop="ruibangUnitName">
							<el-input v-model="formData.ruibangUnitName"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="瑞邦设备类型" prop="ruibangUnitType">
							<el-input v-model="formData.ruibangUnitType"></el-input>
						</el-form-item>
					</el-col>
				
				</el-row>


				<el-row>
					<el-col :span="12">
						<el-form-item label="瑞邦测点类型" prop="ruibangMeasType">
							<el-select v-model="formData.ruibangMeasType" placeholder="请选择">
								<el-option label="温度" value="温度">
								</el-option>
								<el-option label="湿度" value="湿度">
								</el-option>
								<el-option label="振动" value="振动">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="绑定测点" prop="measId">
							<el-cascader v-model="formData.measId" :options="treeData" :props="{
									value: 'id',
									label: 'name',
									children: 'children',
									emitPath: false
								}" @change="handleChange"></el-cascader>
						</el-form-item>
					</el-col>

				</el-row>

			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close()">关闭</el-button>
				<el-button type="primary" @click="submit()">确定</el-button>
			</div>
		</div>
	</el-dialog>
</template>
<script>
	var regu = /^[0-9]+\.?[0-9]*$/;
	import {
		dialogMixin
	} from '@m/dialogMixin';
	import configApi from '@config/configApi';
	export default {
		mixins: [dialogMixin],
		data() {
			return {
				title: '',
				loading: false,
				formData: {},
				treeData: [],
				rules: {
					ruibangMeasId: [{
						required: true,
						message: '请填写瑞邦测点id',
						trigger: 'change'
					}],
					ruibangMeasName: [{
						required: true,
						message: '请填写瑞邦测点名称',
						trigger: 'change'
					}],
					ruibangUnitId: [{
						required: true,
						message: '请填写瑞邦设备id',
						trigger: 'change'
					}],
					ruibangUnitName: [{
						required: true,
						message: '请填写瑞邦设备名称',
						trigger: 'change'
					}],
					ruibangUnitType: [{
						required: true,
						message: '请填写瑞邦设备类型',
						trigger: 'change'
					}],

					ruibangArea: [{
						required: true,
						message: '请填写区域',
						trigger: 'change'
					}],
					ruibangMeasType: [{
						required: true,
						message: '请选择瑞邦测点类型',
						trigger: 'change'
					}],
					measId: [{
						required: true,
						message: '请选绑定测点',
						trigger: 'change'
					}]
				}
			};
		},
		props: {},
		watch: {},
		methods: {
			//打开弹窗
			open() {
				this.formData = {};
				if (this.dialogObj.type == 2) {
					this.get();
				}
				this.$nextTick(() => {
					this.$refs.form.clearValidate();
				});
				this.getmeasList();
			},
			getmeasList() {
				this.$get('/backend-api/eqp/set/meas/list?meas_include=1&meas_suffix=1').then(res => {
					if (res.code != 1000) {
						this.$message.error(res.msg);
						return;
					}
					this.treeData = res.data;
				});
			},

			get() {
				this.$get('/data-api/v1/eqp-set-unit-meas-ruibang/' + this.dialogObj.row.id).then(res => {
					if (res.code == 1000) {
						this.formData = res.data;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			handleChange(value) {
				this.formData.measName = this.findNameById(this.treeData, value)
				this.formData.entName = this.treeData[0].name;
				this.formData.entId = this.treeData[0].ent_id;
			},
			
			findNameById(tree, idToFind) {
				 for (let i = 0; i < tree.length; i++) { // 遍历当前层级的所有节点
				    const node = tree[i];
				    if (node.id === idToFind) { // 如果找到了目标节点，返回它的name属性
				      return node.name;
				    }
				    if (node.children) { // 如果还有子节点，递归查找
				      const name = this.findNameById(node.children, idToFind);
				      if (name) { // 如果找到了目标节点，返回它的name属性
				        return name;
				      }
				    }
				  }
				  return null; // 没有找到目标节点，返回null
			},
			//提交表单
			submit() {
				this.$refs['form'].validate(valid => {
					if (valid) {
						this.loading = true;
						switch (this.formData.ruibangMeasType) {
							case '温度':
								this.formData.ruibangDangwei = '℃'
								break
							case '湿度':
								this.formData.ruibangDangwei = '%RH'
								break
							case '振动':
								this.formData.ruibangDangwei = 'mm/s'
								break
						}
						if (this.dialogObj.type == 2) {
							delete this.formData.ruibangMeasId;
							this.$putJson('/data-api/v1/eqp-set-unit-meas-ruibang', this.formData).then(res => {
								if (res.code == 1000) {
									this.loading = false;
									this.$message.success(res.msg);
									this.$parent.list();
									this.close();
								} else {
									this.loading = false;
									this.$message.error(res.msg);
								}
							});
						} else {
							this.$postData('/data-api/v1/eqp-set-unit-meas-ruibang', this.formData).then(res => {
								if (res.code == 1000) {
									this.loading = false;
									this.$message.success(res.msg);
									this.$parent.list();
									this.close();
								} else {
									this.loading = false;
									this.$message.error(res.msg);
								}
							});

						}



					} else {
						return false;
					}
				});
			},
			//关闭弹窗
			close() {
				this.visiable = false;
			}
		}
	};
</script>
<style lang="scss" scoped>
	.dialog {
		::v-deep .cascader {
			display: block;
		}
	}

	::v-deep input::-webkit-outer-spin-button,
	::v-deep input::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	::v-deep input[type='number'] {
		-moz-appearance: textfield;
	}
</style>